angular.module('CrowdsourceApp').constant('CrowdsourceMapSpeedDialOptions', [
    {
        isOpen: true,
        availableModes: ['md-fling', 'md-scale'],
        selectedMode: 'md-scale',
        selectedDirection: 'up',
        availableDirections: ['up', 'down', 'left', 'right'],
        menuIcon: "plus",
        postion: "bottom-right",
        submenuList: [
            {
                label: "Add Response",
                tooltip: {
                    label: "Add Response",
                    direction: "left"
                },
                action: "draw_marker",
                icon: "map-marker"
            },
            {
                label: "Information",
                tooltip: {
                    label: "Information",
                    direction: "left"
                },
                action: "toggleSideBar",
                icon: "information"
            },
        ]
    }
]);
