angular.module('CrowdsourceApp').config(["$routeProvider", "$resourceProvider",
    function ($routeProvider, $resourceProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when("/", {
                name: 'Crowdsource',
                controller: 'CrowdsourceDashboardController',
                templateUrl: '/static/assets/khapps/crowdsource/dashboard/partials/CrowdsourceDashboard.html'
            })
            .when("/osrcabbagestemflea/", {
                name: 'OSR Cabbage Stem Flea Beetle',
                controller: "CabbageStemFleaController",
                templateUrl: "/static/assets/khapps/crowdsource/CabbageStemFlea/partials/cabbage_stem_flea_dashboard_landing.html"
            })
            .when("/osrsclerotinia/", {
                name: 'OSR Flowwering Growth Map',
                controller: 'OSRGrowthMapController',
                templateUrl: '/static/assets/khapps/crowdsource/OilSeedRapeGrowth/partials/osr_growth_dashboard_landing.html'
            })
            .when("/wheatyellowrust/", {
                name: 'Wheat Yellow Rust Disease Map',
                controller: 'WYRDiseaseMapController',
                templateUrl: '/static/assets/khapps/crowdsource/WheatYellowRustDisease/partials/wyr_disease_dashboard_landing.html'
            })
            .otherwise({
                redirectTo: '/'
            });
    }
]);