angular.module('CrowdsourceApp').controller("CrowdsourceDashboardController", ["$scope", "$rootScope",
    "WheatYellowRustDiseaseAnalyticsFactory", "OilSeedRapeGrowthAnalyticsFactory", "CabbageStemFleaAnalyticsFactory",
    function ($scope, $rootScope, WheatYellowRustDiseaseAnalyticsFactory,
              OilSeedRapeGrowthAnalyticsFactory, CabbageStemFleaAnalyticsFactory) {
        $rootScope.pageTitle = "Crowdsource";
        $rootScope.page_id = "crowdsource";
        $scope.$on("filter:selections_updated", function (obj, filterValue) {
            if (filterValue.hasOwnProperty('growing_season')) {
                getCrowdsourceAppStats($scope, WheatYellowRustDiseaseAnalyticsFactory, OilSeedRapeGrowthAnalyticsFactory, CabbageStemFleaAnalyticsFactory);
            }
        });

        if(typeof($rootScope.selected_growing_season)!=='undefined')
            getCrowdsourceAppStats($scope, WheatYellowRustDiseaseAnalyticsFactory, OilSeedRapeGrowthAnalyticsFactory, CabbageStemFleaAnalyticsFactory);

        function getCrowdsourceAppStats($scope, WheatYellowRustDiseaseAnalyticsFactory, OilSeedRapeGrowthAnalyticsFactory, CabbageStemFleaAnalyticsFactory) {
            $scope.crowdsourceApps = [];
            WheatYellowRustDiseaseAnalyticsFactory.query({growing_season: $rootScope.selected_growing_season.value}, function (data) {
                $scope.crowdsourceApps.push(
                    {
                        "title": "Wheat Yellow Rust",
                        "description": "Wheat Yellow Rust Disease",
                        "category": ["Crop growth", "Crop development"],
                        "featured_image": [{"image_file": require("../../csfb/images/wheatyellowrust.jpg")}],
                        "URL": '/dashboard/crowdsource/#/wheatyellowrust/',
                        "stats": {
                            "growing_season": data.growing_season,
                            "observation_dates": {
                                "earliest": data.analytics.earliest,
                                "latest": data.analytics.latest,
                            },
                            "observations_recorded": {
                                "total": data.analytics.count,
                                "positive": 70,
                                "negative": 10,
                                "neutral": 20
                            }
                        }
                    });
            });

            OilSeedRapeGrowthAnalyticsFactory.query({growing_season: $rootScope.selected_growing_season.value}, function (data) {
                $scope.crowdsourceApps.push(
                    {
                        "title": "Oilseed Rape Sclerotinia",
                        "description": "Flowering Stages of OSR",
                        "category": ["Crop growth", "Crop development"],
                        "featured_image": [{"image_file": require("../../csfb/images/oilseedrape.jpg")}],
                        "URL": '/dashboard/crowdsource/#/osrsclerotinia/',
                        "stats": {
                            "growing_season": data.growing_season,
                            "observation_dates": {
                                "earliest": data.analytics.earliest,
                                "latest": data.analytics.latest,
                            },
                            "observations_recorded": {
                                "total": data.analytics.count,
                                "positive": 70,
                                "negative": 10,
                                "neutral": 20
                            }
                        }
                    });
            });

            CabbageStemFleaAnalyticsFactory.query({growing_season: $rootScope.selected_growing_season.value}, function (data) {
                $scope.crowdsourceApps.push(
                    {
                        "title": "Oilseed Rape Flea Beetle",
                        "description": "Cabbage Stem Flea Beetle Pest Infestation",
                        "category": ["Crop disease", "Crop pest"],
                        "featured_image": [{"image_file": require("../../csfb/images/CSFB.jpeg")}],
                        "URL": '/dashboard/crowdsource/#/osrcabbagestemflea/',
                        "stats": {
                            "growing_season": data.growing_season,
                            "observation_dates": {
                                "earliest": data.analytics.earliest,
                                "latest": data.analytics.latest,
                            },
                            "observations_recorded": {
                                "total": data.analytics.count,
                                "positive": 70,
                                "negative": 10,
                                "neutral": 20
                            }
                        }
                    });
            });
        }

        $scope.showCrowdsourceApp = function (crowdsourceAppsUrl) {
            window.open(crowdsourceAppsUrl);
        }


    }]);